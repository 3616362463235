<script setup>
    const props = defineProps(['id', 'event']);

    onMounted(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: props.event ?? 'ad-container-ready',
            id: props.id
        });
    });
</script>

<template>
    <slot></slot>
    <div class="w-full" :id="id"></div>
</template>